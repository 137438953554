@import url(https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;height:100%;min-height:100%}main{overflow-x:hidden}.ant-table-pagination.ant-pagination{margin:0;padding:16px 0;background-color:#fff}.clickoff{width:10px !important;height:10px !important}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}#root{height:100%;min-height:100%;overflow:hidden}

.site-layout{border-bottom:1px solid #f0f0f0;display:flex;min-height:100vh;flex-direction:column;overflow:hidden}.site-layout-background{border-bottom:1px solid #f0f0f0}.portfolio-header{padding:0;display:flex}.portfolio-menu{flex:7 1;border-bottom:none;text-align:right}.portfolio-content{height:600px;display:flex;flex-direction:column}.portfolio-avatar{flex:1 1;text-align:right;padding-right:56px;background:#fff}.padding-xs{padding:8px}

.ant-btn:focus{color:inherit !important}.ant-btn-circle:focus{border-color:#d9d9d9 !important;color:inherit !important}

.portfolio-table th.ant-table-cell:nth-child(1){width:5px}.portfolio-table .ant-table-header>table:nth-child(1)>colgroup:nth-child(1)>col:nth-child(1){width:5px}

.dot{height:7px;width:7px;border-radius:50%;display:inline-block}.dot-large{height:15px;width:15px}.popup-selected-project .mapboxgl-popup-content{background-color:rgba(255,255,255,0.85);border:0;box-shadow:none;border-radius:10%}.popup-selected-project .mapboxgl-popup-tip{display:none}

#forgeViewer {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #f0f8ff;
}

/* This setting is just for storybook */
.sb-show-main #forgeViewer {
  width: 100%;
  height: 100%;
}

.editable-cell{position:relative}.editable-cell-value-wrap{padding:5px 12px;cursor:pointer}.editable-row:hover .editable-cell-value-wrap{border:1px solid #d9d9d9;border-radius:4px;padding:4px 11px}.disabled-row{background-color:#dcdcdc;pointer-events:none;color:#ababab !important}.ant-table-body{height:1500px}

.table-selection-toolbar-container{background-color:#10239E}.table-selection-toolbar-container button{color:white}.table-selection-toolbar-container button:hover{color:#85A5FF}.table-selection-toolbar-container button:focus{color:#85A5FF}.table-selection-toolbar-container button:active{background-color:#2F54EB}.table-selection-toolbar-menu li:hover{color:#85A5FF !important;background-color:#10239E !important}.table-selection-toolbar-menu li:focus{color:#85A5FF !important;background-color:#10239E !important}

.site-collapse-custom-panel{border-radius:2px;margin-bottom:12px;border:0px;overflow:hidden}.site-collapse-custom-panel .site-collapse-custom-panel{background:#f7f7f7;border:none}.ant-collapse{background:white}

.site-collapse-custom-settings-panel{border-radius:2px;margin-bottom:12px;border:0px;overflow:hidden}.site-collapse-custom-settings-panel .site-collapse-custom-settings-panel{background:#fAfAfA;border:none}.ant-collapse{background:#f0f2f5}

.site-collapse-custom-admin-panel{border-radius:2px;margin-bottom:12px;border:0px;overflow:hidden}.site-collapse-custom-admin-panel .site-collapse-custom-admin-panel{background:#fAfAfA;border:none}.ant-collapse{background:#f0f2f5}

.login-page{display:flex;align-items:center;justify-content:center;width:100%;height:100vh;background-image:url(/static/media/sterling_background.975428bb.png);background-size:cover;overflow-x:hidden}.main-name-wrapper{margin-top:12px;font-size:32px;color:#1D39C4;text-align:center;margin-bottom:35px}.info-wrapper{margin-bottom:24px;font-size:18px;text-align:center;color:#262626}.item-wrapper{margin-bottom:24px}.register-wrapper{margin-top:24px;text-align:center}.down-panel-wrapper{margin-bottom:24px}.login-form{background-color:#FFFFFF;padding:24px;min-width:400px;min-height:480px}.login-form-forgot{float:right}.ant-col-rtl .login-form-forgot{float:left}.login-form-button{width:100%}


