@import '~antd/dist/antd.compact.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
}

main {
  overflow-x: hidden;
}

.ant-table-pagination.ant-pagination {
  margin: 0;
  padding: 16px 0;
  background-color: #fff;
}

//This is a bug in AutodeskForge, there are menu items that posses 100vw, 100vh properties :O

.clickoff {
  width: 10px !important;
  height: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}
