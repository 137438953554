@import url('https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css');

#forgeViewer {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #f0f8ff;
}

/* This setting is just for storybook */
.sb-show-main #forgeViewer {
  width: 100%;
  height: 100%;
}
